<template>
	<div class="index_wrap">
		<div class="index_header">
			<div class="index_header_ttps">
				<div class="tips_left">
					<img  class="logo" src="../assets/logo.png" alt="">
				</div>
				<div class="tips_right">
					<div class="right_text">
						<div class="text_item">
							<img src="../assets/dh.png" alt="">
							<span>0951-7862828</span>
						</div>
						<div class="text_item">
							<img src="../assets/sj.png" alt="">
<!--							<span>18695152532</span>-->
							<span>18709507817</span>
						</div>
					</div>
					<img style="width: 35%" src="../assets/ewm.png" alt="" >
				</div>
			</div>
			<div class="index_header_menu">
				<div :class="[isMenuActive == 'home'?'menuActive':'']" @click="handleMenuItem('home')">首页</div>
				<div :class="[isMenuActive == 'aboutUs'?'menuActive':'']" @click="handleMenuItem('aboutUs')">关于我们</div>
				<div :class="[isMenuActive == 'proCenter'?'menuActive':'']" @click="handleMenuItem('proCenter')">产品中心</div>
				<div :class="[isMenuActive == 'docDown'?'menuActive':'']" @click="handleMenuItem('docDown')">文档下载</div>
				<div :class="[isMenuActive == 'newsComp'?'menuActive':'']" @click="handleMenuItem('newsComp')">新闻动态</div>
				<div :class="[isMenuActive == 'contactUs'?'menuActive':'']" @click="handleMenuItem('contactUs')">联系我们</div>
			</div>
		</div>
		<div class="index_content">
			<router-view ></router-view>
		</div>
		<div class="index_footer_tips">
			<div class="footer_tips_item">
				<p style="color:#ececec;font-size:24px">宁夏创耀信科技有限公司</p>
				<p style="color:#c2c2c2;margin-top:-10px;margin-bottom:-5px">Ningxia Chuangyaoxin Technology Co.,Ltd</p>
				<img  src="../assets/proimg.png" alt="">
				<p style="color:#ececec">以软、硬件研发为核心，致力于智慧牧场、智慧工业等系统的研发及实施的一家大型互联网公司</p>
			</div>
			<div class="footer_tips_item">
				<p style="font-size:60px;margin:0">Contact Us</p>
				<p style="font-size:23px;">联系我们</p>
			</div>
			<div class="footer_tips_item">
				<img style="width:46%" src="../assets/em3.png" alt="">
				<div>
					<img style="width:80%;" src="../assets/ljzx.png" alt="">
					<p style="margin-bottom:0px">全国服务热线</p>
					<p style="margin-top:3px">0951-7862828</p>
				</div>
			</div>
		</div>
		<div class="index_footer">
			<div class="footer_top">
				<div class="footer_item">
					<h3 style="font-weight: bold;">关于我们</h3>
					<img src="../assets/line.png" alt="">
					<p>宁夏创耀信科技有限公司2018年被评为国家级、自治区级科技型中小企业，在宁夏股权托管交易中心挂牌。</p>
					<p>宁夏创耀信科技有限公司到目前为止共拥有25项知识产权，其中2项发明专利、9项实用新型专利和14项软件著作权。</p>
				</div>
				<div class="footer_item">
					<h3 style="font-weight: bold;">最新消息</h3>
					<img src="../assets/line.png" alt="">
					<p>工信部提出，加快发展5G和物联网相关产业，深化信息化和工业化融合发展。</p>
					<p>工信部调整800MHz频段数字集群通信系统频率使用规划</p>
				</div>
				<div class="footer_item">
					<h3 style="font-weight: bold;">技术团队</h3>
					<img src="../assets/line.png" alt="">
					<div class="item_imgs">
						<img src="../assets/rx.png" alt="">
						<img src="../assets/rx.png" alt="">
						<img src="../assets/rx.png" alt="">
						<img src="../assets/rx.png" alt="">
					</div>
				</div>
				<div class="footer_item">
					<h3 style="font-weight: bold;">联系信息</h3>
					<img src="../assets/line.png" alt="">
					<div style="margin-top:20px;display: flex;flex-direction: row;align-items: center">
						<img  src="../assets/dz.png" alt="">
						<p style="margin-left: 26px;">宁夏银川市ibi育成中心2期7号楼506</p>
					</div>
					<div style="display: flex;flex-direction: row;align-items: center">
						<img src="../assets/email.png" alt="">
						<p style="margin-left: 26px;"> cyx@nxcyx.com</p>
					</div>
					<div style="display: flex;flex-direction: row;align-items: center">
						<img src="../assets/phone.png" alt="">
						<p style="margin-left: 26px;">0951-7862828 </p>
					</div>
					<div style="display: flex;flex-direction: row;align-items: center">
						<img class="sj1" src="../assets/sj1.png" alt="">
						<div style="margin-left: 26px;">
<!--							<p>18695152532</p>-->
<!--							<p>13895114707</p>-->
							<p>18709507817</p>
							<p>18695156699</p>
						</div>
					</div>
				</div>
			</div>
			<div class="footer_bottom">
				<p @click="handleToRecord">Copyright © 2015-2022 宁夏创耀信科技有限公司 .   备案：宁ICP备18000715号  </p>
				<div>
					<el-breadcrumb separator="/">
						<el-breadcrumb-item :to="{ path: '/index/home' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/index/aboutUs' }">关于我们</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/index/proCenter' }">产品中心</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/index/docDown' }">文档下载</el-breadcrumb-item>
						<el-breadcrumb-item :to="{ path: '/index/newsComp' }">新闻动态</el-breadcrumb-item>
						<el-breadcrumb-item></el-breadcrumb-item>
					</el-breadcrumb>
				</div>
			</div>
		</div>
		<div :class="['index_right_tips',isTips?'tips_active':'']" @click="handleBackTop" title="返回顶部">
			<i class="el-icon-top"></i>
		</div>
	</div>
</template>

<script>
/**
 * author:duxp,
 * desc:公司网站首页
 */
import HomeIndex from "@/views/components/HomeIndex";
export default {
	name: "Index",
	components:{
		HomeIndex
	},
	data(){
		return {
			isMenuActive:'home',
			isTips:false
		}
	},
	created() {
		this.$router.beforeEach((to, from, next) => {
			let _name = to.name;
			this.isMenuActive = _name;
			if(_name == 'detailsComp'){
				this.isMenuActive = 'newsComp';
			}
			next();
		});
	},
	mounted() {
		this.isMenuActive = this.$router.history.current.name;
		window.addEventListener("scroll",this.scrollEventFn);
	},
	methods:{
		/**
		 * 首页菜单点击事件
		 */
		handleMenuItem(type){
			this.isMenuActive = type;
			switch (type){
				case "home":
					this.$router.replace('/index/home')
					break;
				case "aboutUs":
					this.$router.replace('/index/aboutUs')
					break;
				case "proCenter":
					this.$router.replace('/index/proCenter')
					break;
				case "docDown":
					this.$router.replace('/index/docDown')
					break;
				case "newsComp":
					this.$router.replace('/index/newsComp');
					break;
				case "contactUs":
					this.$router.replace('/index/contactUs');
			}
		},
		/**
		 * 跳转备案号
		 */
		handleToRecord(){
			window.open('https://beian.miit.gov.cn/');
		},
		/**
		 * 监听滚动条位置
		 */
		scrollEventFn(){
			let _t = document.documentElement.scrollTop || document.body.scrollTop;
			console.log(_t);
			if(_t > 300){
				this.isTips = true;
			}else{
				this.isTips = false;
			}
		},
		/**
		 * 点击返回网站顶部
		 */
		handleBackTop(){
			let _scrollTop = document.documentElement.scrollTop-0;
			let _t = setInterval(function (){
				_scrollTop = _scrollTop / 2;
				if(_scrollTop <= 5){
					clearInterval(_t);
					_scrollTop = 0;
				}
				document.documentElement.scrollTop = _scrollTop + 0;
			},60)
		}
	}
}
</script>

<style lang="scss" scoped>
.index_wrap{
	width: 100%;
	height: 100%;
	.index_header{
		box-sizing: border-box;
		padding-top: 10px;
		.index_header_ttps{
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			padding: 0 200px;
			.tips_right{
				display: flex;
				flex-direction: row;
				align-items: center;
				.right_text{
					width: 130px;
					.text_item{
						margin: 10px 0;
						color: #0f91ee;
						img{
							margin-right: 20px;
						}
					}
				}
			}
		}
		.index_header_menu{
			width: 100%;
			height: 50px;
			background-color: #0f91ee;
			display: flex;
			justify-content: center;
			align-items: center;
			div{
				width: 15%;
				height: 100%;
				line-height: 50px;
				text-align: center;
				color: #ffffff;
				font-size: 14px;
				border-left: 1px solid #e4e7e7;
				border-right: 1px solid #e4e7e7;
				cursor: pointer;
				transition: all .5s;
				background-color: #0f91ee;
			}
			.menuActive{
				background-color: #e4e7e7 !important;
				color: #000000 !important;
				transition: all .5s;
			}
		}
	}
	.index_content{

	}
	.index_footer_tips{
		width: 100%;
		height: 196px;
		background-image: url("../assets/2.png");
		display: flex;
		justify-content: center;
		align-items: center;
		.footer_tips_item{
			width: 20%;
			text-align: center;
			margin: 0 20px;
			color: #ffffff;
		}
		.footer_tips_item:nth-child(3){
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}
	.index_footer{
		width: 100%;
		background-image: url("../assets/hb.png");
		.footer_top{
			display: flex;
			justify-content: center;
			.footer_item{
				width: 18%;
				height: 100%;
				color: #ffffff;
				padding: 10px;
				font-size: 14px;
				box-sizing: border-box;
				.item_imgs{
					margin-top: 20px;
					img{
						margin-right: 10px;
					}
				}
			}
		}
		.footer_bottom{
			width: 100%;
			height: 65px;
			background-image: url("../assets/dbdh.png");
			display: flex;
			justify-content: space-around;
			align-items: center;
			color: #ffffff;
			box-sizing: border-box;
			padding: 0 20px;
			p{
				cursor: pointer;
			}
		}
	}
	.index_right_tips{
		position: fixed;
		right: 2%;
		bottom: 4%;
		font-size: 32px;
		cursor: pointer;
		display: none;
		transition: all .5s;
		color: #0f91ee;
		border-radius: 50%;
		z-index: 999;
		background-color: #ffffff;
	}
	.tips_active{
		display: block;
		transition: all .5s;
	}
}
::v-deep .el-breadcrumb__inner.is-link{
	color: #ffffff;
}
</style>
